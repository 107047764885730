.Button_button__nbHC7 {
  font-size: 15px;
  color: white;
  font-weight: 500;
  font-family: var(--header-font);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  justify-content: left;
  border-radius: 3px;
  padding: 9px 15px 9px 15px;
  user-select: none;
}

.Button_medium__ErOH7{
  font-size: 15px;
}

.Button_large__bwDlf{
  font-size: 18px;
}

.Button_small__2s2DA{
  font-size: 12px;
}


.Button_primary__auM7q {
  background-color: var(--color-red-10);
  border: 1px solid var(--color-red-10);
  color: white;
}


.Button_primary__auM7q:disabled {
    background-color: var(--color-grey-shade);
    border: 1px solid var(--color-grey-shade);
  }

.Button_primary__auM7q:hover {
  opacity: 0.8;
}

.Button_secondary__Oorqq {
  background-color: rgb(243, 243, 243);
  color: var(--color-red);
}

.Button_secondary__Oorqq:hover {
  opacity: 0.8;
}

.Button_transparent__fR6qp {
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  color: var(--color-red);
}

.Button_transparent__fR6qp:hover {
  background-color: rgb(243, 243, 243)
}

.Button_link__0w2gj {
  color: var(--color-red-10);
  border: none;
}

.Button_link__0w2gj:hover {
  text-decoration: underline;
  padding: 0;
  text-underline-offset: 5px;
}

.Button_outline__30sbM {
  background-color: transparent;
  color: var(--color-red-10);
  transition: background-color 0.1s ease-in-out;
  border: 1px solid var(--color-red-10);
}

.Button_outline__30sbM:hover {
    background-color: rgb(250, 250, 250)
  }

.Button_outline__30sbM:disabled {
    border: 1px solid var(--color-grey-shade);
    color: var(--color-grey-shade);
  }
.FeefoFooter_outterContainer__TGc6R {
  background-color: var(--color-grey-very-light);
  padding: 60px 0;
  margin-top: 120px;
}

.FeefoFooter_container__9_lGZ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

@media screen and (min-width: 768px) {

.FeefoFooter_container__9_lGZ {
    flex-direction: row
}
  }

.FeefoFooter_award__Vxmzw {
  position: relative;
  width: 200px;
  height: 200px;
}

@media screen and (min-width: 768px) {

.FeefoFooter_award__Vxmzw {
    width: 100px;
    height: 100px
}
  }

.FeefoFooter_feefoLogo__m4rRH {
  position: relative;
  width: 98px;
  height: 24px;
}

.FeefoFooter_title__P9REj {
  display: grid;
  grid-template-rows: auto auto; /* Two rows */
  grid-template-columns: repeat(4, auto); /* 4 columns */
  gap: 10px;
  line-height: 4em;
  text-transform: initial;
  font-family: var(--font-family-body);
  color: var(--color-grey-deep);
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* First element spans all 4 columns */
.FeefoFooter_title__P9REj > *:first-child {
  grid-row: 1;
  grid-column: 1 / -1; /* Spans from first to last column */
}

/* All other elements sit on row 2, one per column */
.FeefoFooter_title__P9REj > *:not(:first-child) {
  grid-row: 2;
}

@media screen and (min-width: 768px) {
  .FeefoFooter_title__P9REj {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }
}

.FeefoFooter_stars__v381R {
  color: var(--color-star);
}

.FeefoFooter_stars__v381R>svg {
    margin-right: 0;
  }

.FeefoFooter_rating__EnWE6 {
  font-size: 1.2em;
}

.FeefoFooter_foot__j_2Yj {
  font-size: 14px;
  text-align: center;
}

.FeefoFooter_foot__j_2Yj>strong {
    font-weight: 600;
    color: var(--color-grey-deep);
  }

.FeefoFooter_foot__j_2Yj>button {
    margin-left: 10px;
    text-decoration: underline;
  }

.FeefoFooter_foot__j_2Yj>a {
    margin-left: 10px;
  }

.FeefoFooter_reviews__cWy_t {
  display: none;
  flex-direction: row;
  border-top: 1px solid var(--color-grey);
  margin-top: 30px;
  padding-top: 30px;
  gap: 30px;
}

@media screen and (min-width: 768px) {

.FeefoFooter_reviews__cWy_t {
    display: flex
}
  }

.FeefoFooter_review__Cqokd {
  flex-grow: 1;
  width: 25%;
}

.FeefoFooter_reviewTitle__urCM8 {
  font-size: 14px;
  font-weight: 800;
}

.FeefoFooter_reviewText__DwbEi {
  font-size: 14px;
  margin-top: 5px;
}

.FeefoFooter_reviewDetails__PWnQ4 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 12px;
  margin-top: 5px;
  color: var(--color-grey-dark);
}

.FeefoFooter_customerName__dpLuG {

}

.FeefoFooter_date__VPs2r {

}


.StarRating_stars__oGTkw {
  color: var(--color-star);
}

  .StarRating_stars__oGTkw>svg {
    margin-right: 0;
  }
.Link_linkStyle__qTkbl {
  text-decoration: none;
}

.Link_button__GDhNO {
  font-size: 15px;
  color: white;
  font-weight: 400;
  font-family: var(--header-font);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  justify-content: left;
  border-radius: 3px;
  padding: 10px 15px 10px 15px;
}


.Link_primary-red__vKuDf {
  background-color: var(--color-red-10);
  color: white;
}

.Link_medium__oOvlP{
  font-size: 15px;
}

.Link_large__6AuVn{
  font-size: 18px;
}

.Link_small__WUzly{
  font-size: 12px;
}

.Link_primary-red__vKuDf:hover {
  opacity: 0.8;
}

.Link_secondary__nxg8f {
  background-color: rgb(243, 243, 243);
  color: var(--color-red-10);
}

.Link_secondary__nxg8f:hover {
  opacity: 0.8;
}

.Link_transparent__b44AB {
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  color: var(--color-red);
}

.Link_transparent__b44AB:hover {
  background-color: rgb(243, 243, 243)
}

.Link_link__5Eq3f {
  color: var(--color-red-10);
  border: none;
}

.Link_link__5Eq3f:hover {
  text-decoration: underline;
  padding: 0;
  text-underline-offset: 5px;
}

.Link_outline-red__TxNLQ {
  background-color: transparent;
  color: var(--color-red-10);
  transition: background-color 0.1s ease-in-out;
  border: 1px solid var(--color-red-10);
}

.Link_outline-red__TxNLQ:hover {
  background-color: rgb(250, 250, 250)
}


.Link_outline-white__wh0g6 {
  background-color: transparent;
  color: var(--color-red);
  transition: background-color 0.1s ease-in-out, backdrop-filter 100ms ease-in-out;
  border: 1px solid white;
  color: white;
}

.Link_outline-white__wh0g6:hover {
  background-color: rgba(250, 250, 250, 0.2);
  backdrop-filter: blur(2px);
}
.HolidayResult_wrapper__E3TuF {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
}

  .HolidayResult_wrapper__E3TuF > header {
    display: flex;
    flex-grow: 1;
    padding: 24px;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
  }

  .HolidayResult_wrapper__E3TuF > footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 30%;
    width: 30%;
    padding: 24px;
    border: 1px solid var(--color-grey-tint);
    border-left: 0;
    border-radius: 0 10px 10px 0;
  }

  @media screen and (max-width: 1199px) {.HolidayResult_wrapper__E3TuF {
    flex-direction: column
}

    .HolidayResult_wrapper__E3TuF > header {
      border-radius: 10px 10px 0 0;
      gap: 120px;
    }

    .HolidayResult_wrapper__E3TuF > footer {
      border-radius: 0 0 10px 10px;
      width: 100%;
      border-left: 1px solid var(--color-grey-tint);
    }
  }

.HolidayResult_footerTop__QpsNU {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: stretch;
  justify-content: space-between;
}


.HolidayResult_header___NmC8 {
  position: relative;
}

.HolidayResult_darkGradientOverlay__ks3X_ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgb(30, 30, 30) 0%, rgb(60, 60, 60) 25%,  rgb(255 255 255) 45%, rgb(255 255 255) 60%,  rgb(56 56 56));
}

@media screen and (max-width: 1199px) {

.HolidayResult_darkGradientOverlay__ks3X_ {
    background: rgba(0, 0, 0, 0.8);
}
  }

.HolidayResult_darkGradientOverlay__ks3X_ {

  mix-blend-mode: multiply;
  opacity: 0.6;
}

.HolidayResult_headerTop__xMKPB {
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  z-index: 1;
}

.HolidayResult_headerBottom__6BbEN {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.HolidayResult_headerDetails__Z5jKQ {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: calc(100% - 71px);
}

.HolidayResult_headerDetails__Z5jKQ > h3, .HolidayResult_headerDetails__Z5jKQ > .HolidayResult_itineraryContainer__IM4eF, .HolidayResult_headerDetails__Z5jKQ > .HolidayResult_details__y_q_3 {
    margin-top: -0.1em;
    margin-bottom: -0.1em;
    line-height: 1em;
  }

.HolidayResult_headerDetails__Z5jKQ > .HolidayResult_itineraryContainer__IM4eF {
    margin-bottom: -0.2em;
  }

.HolidayResult_headerDetails__Z5jKQ > h3 {
    margin: 0;
    color: var(--color-white);
    font-family: var(--font-header);
    font-weight: 500;
    font-size: 32px;
    margin-top: -0.15em;
    margin-bottom: -0.15em;
  }

@media screen and (max-width: 991px) {

.HolidayResult_headerDetails__Z5jKQ {
    gap: 20px
}
    .HolidayResult_headerDetails__Z5jKQ > h3 {
      font-size: 24px;
      min-height: calc(64px + 10px);
    }
  }

.HolidayResult_itineraryContainer__IM4eF {
  max-width: 100%;
}

@media screen and (min-width: 992px) {

.HolidayResult_itineraryContainer__IM4eF {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-mask-image: linear-gradient(to right, black 80%, transparent 100%);
    height: 1.2em
}
  }

@media screen and (max-width: 991px) {

.HolidayResult_itineraryContainer__IM4eF {
    margin-left: calc(-51px - 20px);
    max-width: calc(100% + 20px + 51px)
}
  }

.HolidayResult_itinerary__gzet_ {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--color-white);
}

@media screen and (max-width: 991px) {

.HolidayResult_itinerary__gzet_ {
    flex-wrap: wrap
}
  }

.HolidayResult_itinerary__gzet_ > span {
    white-space: nowrap;
  }

.HolidayResult_details__y_q_3 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: var(--color-white);
  font-size: 14px;
}

@media screen and (max-width: 991px) {

.HolidayResult_details__y_q_3 {
    flex-direction: column;
    gap: 10px;
    margin-left: calc(-51px - 20px);
    max-width: calc(100% + 20px + 51px)
}
  }

.HolidayResult_details__y_q_3 > span {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

.HolidayResult_details__y_q_3 > span > svg {
      font-size: 16px;
    }

.HolidayResult_headerBottom__6BbEN {
  position: relative;
  z-index: 1;
}

.HolidayResult_cruiseLineLogo__y3Q_L {
  position: relative;
  width: auto;
  max-height: 64px;
  max-width: 108px;
  flex-grow: 1;
}

.HolidayResult_cruiseLineLogo__y3Q_L .HolidayResult_cruiseLineLogoImage__PoILb {
    object-fit: contain !important;
    object-position: left;
    image-rendering: crisp-edges;
  }

.HolidayResult_promotions__SMXYT {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.HolidayResult_includedSection__LaFY_ {
  display: flex;
  gap: 30px;
}

@media screen and (max-width: 1199px) {

.HolidayResult_includedSection__LaFY_ {
    flex-direction: column;
    gap: 10px
}
  }

.HolidayResult_includedLabel__0q8Hr {
  padding: 0;
  color: white;
  font-family: var(--font-header);
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 24px;
}

@media screen and (max-width: 991px) {

.HolidayResult_includedLabel__0q8Hr {
    font-size: 20px
}
  }

.HolidayResult_includeds__uHsdw {
  display: flex;
  gap: 30px;
  padding: 0;
  color: white;
}

.HolidayResult_include__45Fhr {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
}

@media screen and (max-width: 991px) {

.HolidayResult_include__45Fhr {
    font-size: 16px
}
  }

.HolidayResult_buttonContainer__NZ__C {
  display: block;
}

.HolidayResult_buttonContainer__NZ__C>a {
    display: block;
    width: 100%;
  }

.HolidayResult_viewButton__tGKqA {
  padding: 14px;
}

.HolidayResult_starbuy__PrqUg .HolidayResult_viewButton__tGKqA {
    background: var(--color-star-buy-gradient);
  }

.HolidayResult_disclaimer__cvpCX {
  font-size: 12px;
  color: var(--color-grey-medium);
  font-family: var(--font-content);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  max-width: 1137px;
}
.Promotions_promotion__oQqS4 {
  display: flex;
  gap: 7px;
  border-radius: 5px;
  line-height: 1em;
  padding: 12px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.05em;
  background: var(--color-promo-gradient);
}


.Promotions_alertDialogContent__MSx3_ {
  width: 550px;
  cursor: initial;
  padding: 30px;
  border-radius: 10px;
}

.Promotions_alertDialogSubitle__RBDW6{
  margin-top: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.Promotions_alertDialogTitle__O5vgk{
  color: var(--color-maroon);
  padding-right: 60px;
}

.Promotions_alertDialogDescriptionWrapper__fdLtH{
  margin-top: 20px;
}

.Promotions_alertDialogDescription__Ln_b_{
  font-size: 14px;
  line-height: 30px;
}

.Promotions_alertDialogAsterisk__zpwEF{
  margin-top: 50px;
  font-size: 12px;
}


.Promotions_alertDialogAsterisk__zpwEF span{
  font-weight: bold;
}

.Promotions_alertDialogClose__KSGzq {
  position: absolute;
  top: 15px;
  color: white;
  right: 15px;
  cursor: pointer;
}

.Promotions_alertDialogCloseIcon__mXkMI {
  color: var(--color-red);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.Promotions_alertDialogClose__KSGzq:hover {
  background-color: transparent;
}
.Form_formGroup__51DOa {
}

.Form_label__6T5H2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Form_input__T16sw {
  padding: 10px;
  border: 1px solid var(--color-grey-shade);
  border-radius: 3px;
}
.TabberGroupCollection_tabWrapper__3p1BO {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.TabberGroupCollection_holidays__VRE85 {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  margin-bottom: 30px;
  margin-top: 30px;
}
.YouTubeVideo_wrapper__hQrQ4 {
    padding: 56.25% 0 0 0;
    position: relative;
}

.YouTubeVideo_iframe__2fVYO {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .5);
}
.Articles_articles___mbVy {
  display: flex;
  flex-direction: row;
  gap: 28px;
}

  @media screen and (max-width: 1195px) {.Articles_articles___mbVy {
    flex-direction: column
}
  }

.Articles_articleGroup__zMhYB {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (max-width: 755px) {

.Articles_articleGroup__zMhYB {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px
}
  }

.Articles_article__dEjGh {
  display: flex;
  height: 100px;
  width: 330px;
  border-radius: 5px;
  border: 1px solid #f4081f;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  outline: 4px solid #fff;
}

@media screen and (max-width: 1493px) {

.Articles_article__dEjGh {
    width: 300px
}
  }

@media screen and (max-width: 1313px) {

.Articles_article__dEjGh {
    width: 270px
}
  }

@media screen and (max-width: 1195px) {

.Articles_article__dEjGh {
    width: 450px
}
  }

@media screen and (max-width: 991px) {

.Articles_article__dEjGh {
    width: 350px
}
  }

@media screen and (max-width: 755px) {

.Articles_article__dEjGh {
    width: 100%
}
  }

.Articles_article__dEjGh h5 {
  text-align: center;
  font-weight: 500;
  color: #000000;
}

.Articles_article__dEjGh:hover {
  outline: 1.2px solid #f4081f;
}

