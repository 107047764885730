.button {
  font-size: 15px;
  color: white;
  font-weight: 500;
  font-family: var(--header-font);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  justify-content: left;
  border-radius: 3px;
  padding: 9px 15px 9px 15px;
  user-select: none;
}

.medium{
  font-size: 15px;
}

.large{
  font-size: 18px;
}

.small{
  font-size: 12px;
}


.primary {
  background-color: var(--color-red-10);
  border: 1px solid var(--color-red-10);
  color: white;

  &:disabled {
    background-color: var(--color-grey-shade);
    border: 1px solid var(--color-grey-shade);
  }
}

.primary:hover {
  opacity: 0.8;
}

.secondary {
  background-color: rgb(243, 243, 243);
  color: var(--color-red);
}

.secondary:hover {
  opacity: 0.8;
}

.transparent {
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  color: var(--color-red);
}

.transparent:hover {
  background-color: rgb(243, 243, 243)
}

.link {
  color: var(--color-red-10);
  border: none;
}

.link:hover {
  text-decoration: underline;
  padding: 0;
  text-underline-offset: 5px;
}

.outline {
  background-color: transparent;
  color: var(--color-red-10);
  transition: background-color 0.1s ease-in-out;
  border: 1px solid var(--color-red-10);

  &:hover {
    background-color: rgb(250, 250, 250)
  }

  &:disabled {
    border: 1px solid var(--color-grey-shade);
    color: var(--color-grey-shade);
  }
}