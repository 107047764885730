.wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 5px;

  & > header {
    display: flex;
    flex-grow: 1;
    padding: 24px;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
  }

  & > footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 30%;
    width: 30%;
    padding: 24px;
    border: 1px solid var(--color-grey-tint);
    border-left: 0;
    border-radius: 0 10px 10px 0;
  }

  @media screen and (max-width: 1199px) {
    flex-direction: column;

    & > header {
      border-radius: 10px 10px 0 0;
      gap: 120px;
    }

    & > footer {
      border-radius: 0 0 10px 10px;
      width: 100%;
      border-left: 1px solid var(--color-grey-tint);
    }
  }
}

.footerTop {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: stretch;
  justify-content: space-between;
}


.header {
  position: relative;
}

.darkGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgb(30, 30, 30) 0%, rgb(60, 60, 60) 25%,  rgb(255 255 255) 45%, rgb(255 255 255) 60%,  rgb(56 56 56));

  @media screen and (max-width: 1199px) {
    background: rgba(0, 0, 0, 0.8);
  }

  mix-blend-mode: multiply;
  opacity: 0.6;
}

.headerTop {
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  z-index: 1;
}

.headerBottom {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.headerDetails {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: calc(100% - 71px);

  & > h3, & > .itineraryContainer, & > .details {
    margin-top: -0.1em;
    margin-bottom: -0.1em;
    line-height: 1em;
  }

  & > .itineraryContainer {
    margin-bottom: -0.2em;
  }

  & > h3 {
    margin: 0;
    color: var(--color-white);
    font-family: var(--font-header);
    font-weight: 500;
    font-size: 32px;
    margin-top: -0.15em;
    margin-bottom: -0.15em;
  }

  @media screen and (max-width: 991px) {
    gap: 20px;
    & > h3 {
      font-size: 24px;
      min-height: calc(64px + 10px);
    }
  }
}

.itineraryContainer {
  max-width: 100%;

  @media screen and (min-width: 992px) {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-mask-image: linear-gradient(to right, black 80%, transparent 100%);
    height: 1.2em;
  }

  @media screen and (max-width: 991px) {
    margin-left: calc(-51px - 20px);
    max-width: calc(100% + 20px + 51px);
  }
}

.itinerary {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--color-white);

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }

  & > span {
    white-space: nowrap;
  }
}

.details {
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: var(--color-white);
  font-size: 14px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 10px;
    margin-left: calc(-51px - 20px);
    max-width: calc(100% + 20px + 51px);
  }
  
  & > span {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    & > svg {
      font-size: 16px;
    }
  }
}

.headerBottom {
  position: relative;
  z-index: 1;
}

.cruiseLineLogo {
  position: relative;
  width: auto;
  max-height: 64px;
  max-width: 108px;
  flex-grow: 1;

  .cruiseLineLogoImage {
    object-fit: contain !important;
    object-position: left;
    image-rendering: crisp-edges;
  }
}

.promotions {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.includedSection {
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    gap: 10px;
  }
}

.includedLabel {
  padding: 0;
  color: white;
  font-family: var(--font-header);
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 24px;

  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
}

.includeds {
  display: flex;
  gap: 30px;
  padding: 0;
  color: white;
}

.include {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
}

.buttonContainer {
  display: block;

  &>a {
    display: block;
    width: 100%;
  }
}

.viewButton {
  padding: 14px;
}

.starbuy {
  .viewButton {
    background: var(--color-star-buy-gradient);
  }
}

.disclaimer {
  font-size: 12px;
  color: var(--color-grey-medium);
  font-family: var(--font-content);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  max-width: 1137px;
}