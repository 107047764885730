.promotion {
  display: flex;
  gap: 7px;
  border-radius: 5px;
  line-height: 1em;
  padding: 12px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.05em;
  background: var(--color-promo-gradient);
}


.alertDialogContent {
  width: 550px;
  cursor: initial;
  padding: 30px;
  border-radius: 10px;
}

.alertDialogSubitle{
  margin-top: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.alertDialogTitle{
  color: var(--color-maroon);
  padding-right: 60px;
}

.alertDialogDescriptionWrapper{
  margin-top: 20px;
}

.alertDialogDescription{
  font-size: 14px;
  line-height: 30px;
}

.alertDialogAsterisk{
  margin-top: 50px;
  font-size: 12px;
}


.alertDialogAsterisk span{
  font-weight: bold;
}

.alertDialogClose {
  position: absolute;
  top: 15px;
  color: white;
  right: 15px;
  cursor: pointer;
}

.alertDialogCloseIcon {
  color: var(--color-red);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.alertDialogClose:hover {
  background-color: transparent;
}